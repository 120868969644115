<!-- ======= Resume Section ======= -->
<section id="resume" class="resume">
  <div class="container">

    <div class="section-title">
      <div class="d-flex justify-content-between">
        <h2>Resume</h2>
        <a href="https://firebasestorage.googleapis.com/v0/b/thanos-9b12c.appspot.com/o/resume%2F21-09-2023%2FJagadeesh_Resume.pdf?alt=media&token=f36cf274-d16b-4b41-90d7-45cbc70abbc1"
          class="buttonDownload" target="_blank">Download</a>
      </div>
      <p>I am a Full Stack Developer with 3+ years of experience and am currently pursuing masters degree from Santa
        Clara University, CA, USA. I have completed my Bacholer Degree from Indian Institute of
        Technology Jodhpur, India.
        In previous roles, I slashed downtime by 25% and ensured 97% on-time project completion. Also identified and
        dealt with a significant process bottleneck that boosted coding efficiency by 35% when resolved.
        I am passionate about programming and learning about the application of Block Chain.
        I have an experience in Angular, Flutter, C++, Python, Ruby, Graph Databases, SQL, and MongoDB.
        On a personal front, I am disciplined, organized and friendly. I am focused, hard-working and strive hard to
        achieve my goals and Fitness, Gaming Enthusiast.</p>
      <!-- <p>I am a Full Stack Developer and had completed my Bacholer Degree from Indian Institute of
        Technology, Jodhpur
        I am passionate about programming and learning about application of Block Chain.
        I have an experience on Angular, Flutter, Solidity ,C++, Python ,Ruby, Graph Databases, SQL, MongoDB, File Coin,
        Ethereum, CeramicDB.
        On a personal front I am disciplined, organized and friendly. I am focused, hard working and strive hard to
        achieve my goals and Fitness , Gaming Enthusiast.</p> -->
    </div>

    <div class="row">
      <div class="col-lg-6" data-aos="fade-up">
        <h3 class="resume-title">Summary</h3>
        <div class="resume-item pb-0">
          <h4>Jagadeesh Marali</h4>
          <p><em>Innovative and deadline-driven Developer with {{experience}}+ years of experience
              developing different type of applications
            </em></p>
          <ul>
            <li>Santa Clara,California, United States</li>
            <li>contact@jagadeeshmarali.xyz</li>
          </ul>
        </div>


        <h3 class="resume-title">Professional Experience</h3>
        <div class="resume-item">
          <h4>Software Engineer (FIH Santa Clara University)</h4>
          <h5>May 2023 - Present</h5>
          <p><em>Santa Clara, United States </em></p>
        </div>
        <div class="resume-item">
          <h4>Software Engineer (2Sigma School)</h4>
          <h5>July 2023 - September 2023</h5>
          <p><em>Santa Clara, United States </em></p>
        </div>
        <div class="resume-item">
          <h4>Software Engineer (ThinkDeeply)</h4>
          <h5>March 2022 - September 2022</h5>
          <p><em>Remote </em></p>
        </div>
        <div class="resume-item">
          <h4>Software Engineer (Heresy Info Tech)</h4>
          <h5>February 2020 - March 2022</h5>
          <p><em>Remote </em></p>
        </div>
        <div class="resume-item">
          <h4>System Engineer Specialist (Infosys)</h4>
          <h5>August 2019 - February 2020 (7 months)</h5>
          <p><em>Mysore,India</em></p>
        </div>

      </div>
      <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
        <h3 class="resume-title">Education</h3>
        <div class="resume-item">
          <h4>Master's in Computer Science And Engineering</h4>
          <h5>2022 - Present</h5>
          <p><em>Santa Clara University, California, USA</em></p>
        </div>
        <div class="resume-item">
          <h4>Bachelor of Computer Science And Engineering</h4>
          <h5>2015 - 2019</h5>
          <p><em>Indian Institute of Technology Jodhpur, Jodhpur, Rajastan</em></p>
        </div>
        <div class="resume-item">
          <h4>Higher Secondary</h4>
          <h5>2013 - 2015</h5>
          <p><em>Sri Chaitanya Junior College, Vijayawada, A.P</em></p>
        </div>
        <div class="resume-item">
          <h4>Secondary</h4>
          <h5>2013</h5>
          <p><em>Sri Chaitanya Techno Schools, Vijayawada, A.P</em></p>
        </div>
        <!-- <h3 class="resume-title">Projects</h3> -->
        <!-- <div class="resume-item">
          <h4>Movie Recommendation System</h4>
          <h5>01/2018 - 05/2018</h5>
          <ul>
            <li>Recommend movies using analysis of user’s psychological profile</li>
            <li>Preprocessing is done on TMDB dataset using basic NLP
              techniques</li>
            <li>The data is trained with CNN and content based recommendation
              are made</li>
          </ul>
        </div>
        <div class="resume-item">
          <h4>Converting Facial Expressions Into Emoji</h4>
          <h5>05/2018 - 07/2018</h5>
          <ul>
            <li>Emojis can be generated from facial expressions without typing in
              keyboard</li>
            <li>Data collection and facial features extraction are done through
              OpenCv</li>
            <li>CNN is used to generate emoji from facial expression</li>
          </ul>
        </div>
        <div class="resume-item">
          <h4>Finding Mathematical Expressions In Handwritten
            Document</h4>
          <h5>05/2018 - 12/2018</h5>
          <ul>
            <li>Converting handwritten mathematical expressions into their Latex code</li>
            <li>Character Recognition is done through OpenCv and classification
              using CNN</li>
          </ul>
        </div>
        <div class="resume-item">
          <h4>Intranet Chatting System</h4>
          <h5>01/2019 - 05/2019</h5>
          <ul>
            <li>Used to send the Text messages and files in the intranet</li>
            <li>The messages are sent in the form of Packets and TCP Protocol
              is used for sending the packets.</li>
            <li>Tkinter , Network Protocols are used .</li>
          </ul>
        </div> -->
      </div>
    </div>

  </div>
</section><!-- End Resume Section -->