<!-- ======= Skills Section ======= -->
<section id="skills" class="skills section-bg">
  <div class="container">

    <div class="section-title">
      <h2>Skills</h2>
    </div>

    <div class="row skills-content">

      <div class="col-lg-6" data-aos="fade-up">
        <ul>
          <li>
            <span class="skill">Angular</span>
          </li>
          <li>
            <span class="skill">Python</span>
          </li>
          <li>
            <span class="skill">Solidity</span>
          </li>
          <li>
            <span class="skill">C++</span>
          </li>
          <li>
            <span class="skill">Flutter</span>
          </li>
          <li>
            <span class="skill">Dart</span>
          </li>
          <li>
            <span class="skill">Ruby on Rails</span>
          </li>
        </ul>

      </div>
      <div class="col-lg-6" data-aos="fade-up">
        <ul>
          <li>
            <span class="skill">MongoDB</span>
          </li>
          <li>
            <span class="skill">Postgres</span>
          </li>
          <li>
            <span class="skill">Dgraph</span>
          </li>
          <li>
            <span class="skill">CeramicDB</span>
          </li>
          <li>
            <span class="skill">Neo4j</span>
          </li>
          <li>
            <span class="skill">Grpahql</span>
          </li>
          <li>
            <span class="skill">gRPC</span>
          </li>
        </ul>
      </div>


    </div>

  </div>
</section><!-- End Skills Section -->