<!-- ======= About Section ======= -->
<section id="about" class="about">
  <div class="container">

    <div class="section-title">
      <h2>About</h2>
      <p>I am a Full Stack Developer with 3+ years of experience and am currently pursuing masters degree from Santa
        Clara University,CA,USA. I have completed my Bacholer Degree from Indian Institute of
        Technology Jodhpur, India.
        In previous roles, I slashed downtime by 25% and ensured 97% on-time project completion. Also identified and
        dealt with a significant process bottleneck that boosted coding efficiency by 35% when resolved.
        I am passionate about programming and learning about the application of Block Chain.
        I have an experience in Angular, Flutter, C++, Python, Ruby, Graph Databases, SQL, and MongoDB.
        On a personal front, I am disciplined, organized and friendly. I am focused, hard-working and strive hard to
        achieve my goals and Fitness, Gaming Enthusiast.</p>
      <!-- <p>I am a Full Stack Developer and had completed my Bacholer Degree from Indian Institute of
        Technology, Jodhpur
        I am passionate about programming and learning about application of Block Chain.
        I have an experience on Angular, Flutter, Solidity ,C++, Python ,Ruby, Graph Databases, SQL, MongoDB, File Coin,
        Ethereum, CeramicDB.
        On a personal front I am disciplined, organized and friendly. I am focused, hard working and strive hard to
        achieve my goals and Fitness , Gaming Enthusiast.</p> -->
    </div>

    <div class="row">
      <div class="col-lg-4" data-aos="fade-right">
        <img src="assets/img/profile-img.jpeg" class="img-fluid" alt="">
      </div>
      <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
        <h3>Full Stack Developer</h3>
        <!-- <p class="font-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore
            magna aliqua.
          </p> -->
        <div class="row">
          <div class="col-lg-6">
            <ul>
              <li><i class="icofont-rounded-right"></i> <strong>Birthday:</strong> 26 May 1997</li>
              <!-- <li><i class="icofont-rounded-right"></i> <strong>Website:</strong> www.example.com</li> -->
              <!-- <li><i class="icofont-rounded-right"></i> <strong>Phone:</strong> +91-6281163177</li> -->
              <li><i class="icofont-rounded-right"></i> <strong>Email:</strong> contact@jagadeeshmarali.xyz</li>
              <li><i class="icofont-rounded-right"></i> <strong>City:</strong> Hyderabad</li>
            </ul>
          </div>
          <div class="col-lg-6">
            <ul>
              <li><i class="icofont-rounded-right"></i> <strong>Age:</strong> {{age}}</li>
              <li><i class="icofont-rounded-right"></i> <strong>Degree:</strong> B.Tech</li>
              <li><i class="icofont-rounded-right"></i> <strong>Freelance:</strong> Available</li>
            </ul>
          </div>
        </div>
        <!-- <p>
            Officiis eligendi itaque labore et dolorum mollitia officiis optio vero. Quisquam sunt adipisci omnis et
            ut. Nulla accusantium dolor incidunt officia tempore. Et eius omnis.
            Cupiditate ut dicta maxime officiis quidem quia. Sed et consectetur qui quia repellendus itaque neque.
            Aliquid amet quidem ut quaerat cupiditate. Ab et eum qui repellendus omnis culpa magni laudantium dolores.
          </p> -->
      </div>
    </div>

  </div>
</section><!-- End About Section -->