<button type="button" class="mobile-nav-toggle d-xl-none"><i class="icofont-navigation-menu"></i></button>

<!-- ======= Header ======= -->
<header id="header">
  <div class="d-flex flex-column">

    <div class="profile">
      <img src="assets/img/profile-img.jpeg" alt="" class="img-fluid rounded-circle">
      <h1 class="text-light"><a href="index.html">Jagadeesh Marali</a></h1>
      <div class="social-links mt-3 text-center">
        <a href="https://github.com/jagadeeshmarali/" class="google-plus"><i class="bx bxl-github"></i></a>
        <a href="https://www.linkedin.com/in/jagadeesh-marali-53a369142/" class="linkedin"><i
            class="bx bxl-linkedin"></i></a>
        <a href="https://jagadeeshmarali.medium.com/" class="medium"><i class="bx bxl-medium"></i></a>
        <a href="https://twitter.com/jagadeeshmarali" class="facebook"><i class="bx bxl-twitter"></i></a>
      </div>
    </div>

    <nav class="nav-menu">
      <ul>
        <li class="active"><a href="index.html"><i class="bx bx-home"></i> <span>Home</span></a></li>
        <li (click)="scroll('about')"><a><i class="bx bx-user"></i> <span>About</span></a></li>
        <li><a href="https://apps.jagadeeshmarali.xyz/"><i class="bx bx-book"></i> <span>Projects</span></a></li>
        <li (click)="scroll('resume')"><a><i class="bx bx-file-blank"></i> <span>Resume</span></a></li>
        <!-- <li (click)="scroll('portfolio')"><a><i class="bx bx-book-content"></i> Portfolio</a></li> -->
        <li (click)="scroll('blogs')"><a><i class="bx bx-server"></i> Blogs</a></li>
        <!-- <li (click)="scroll('contact')"><a><i class="bx bx-envelope"></i> Contact</a></li> -->

      </ul>
    </nav><!-- .nav-menu -->
    <button type="button" class="mobile-nav-toggle d-xl-none"><i class="icofont-navigation-menu"></i></button>

  </div>
</header><!-- End Header -->