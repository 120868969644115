// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  recaptcha: {
    siteKey: '6LeT9ikdAAAAANNjqKy_uRhxl19uLMh1O_6SA5YM',
  },
  contact_me_api: 'http://localhost:5001/thanos-9b12c/us-central1/webApi/api/v1/contact_me',
  firebaseConfig: {
    apiKey: "AIzaSyA85ma7w9xGepHTr2rMb-tC3WI0ljlzzfg",
    authDomain: "thanos-9b12c.firebaseapp.com",
    projectId: "thanos-9b12c",
    storageBucket: "thanos-9b12c.appspot.com",
    messagingSenderId: "652597327896",
    appId: "1:652597327896:web:da544b953bcfce45112b6d",
    measurementId: "G-8V4D5EPV75"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
